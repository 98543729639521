let Scroll = require('react-scroll');

let {scroller} = Scroll;

// Somewhere else, even another file


exports.onRouteUpdate = ({ location, prevLocation }) => {
  if (location.hash) { // scroll to anchor
    // console.log(location.hash);
    scroller.scrollTo(location.hash.replace('#',''), {
      duration: 500,
      delay: 0,
      smooth: true,
      offset: 600, // Scrolls to element + 50 pixels down the page
    });
  } else {
    // animateScroll.scrollToTop();
  }
};
